<template>
	<!--====================  header area ====================-->
	<div class="header-area">
		<div class="header-area__desktop header-area__desktop--default">
			<!--=======  header info area  =======-->
			<div class="header-info-area" style="padding: 10px">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-lg-12">
							<div class="header-info-wrapper align-items-center">
								<!-- logo -->
								<div class="logo">
									<router-link to="/">
										<img
											src="assets/img/logo/logo-payforu.svg"
											class="img-fluid"
											alt="Brand Logo"
										/>
									</router-link>
								</div>
								<!-- header contact info -->
								<div v-if="!name" class="header-contact-info">
									<div class="header-info-single-item">
										<div class="header-info-single">
											<img
												src="assets/img/icons/user.svg"
												style="width: 40px; padding-right: 5px"
												alt="User"
											/>
										</div>
										<div class="header-info-single-item__content">
											<h6 class="header-info-single-item__title">
												<router-link to="/login" class="pr-1">Iniciar Sesión</router-link
												>|
												<router-link to="/signup" class="pr-1">Regístrate</router-link>
											</h6>
											<p class="header-info-single-item__subtitle">
												Afíliate a nuestro botón de pago
											</p>
										</div>
									</div>
								</div>
								<div v-if="name" class="header-contact-info">
									<div class="header-info-single-item">
										<div class="header-info-single">
											<img
												src="assets/img/icons/user.svg"
												style="width: 40px; margin-right: 10px"
												alt="User"
											/>
										</div>
										<div class="header-info-single-item__content">
											<h6 class="header-info-single-item__title pr-1">
												Hola, {{ name }} {{ lastname }}
											</h6>
											<p class="header-info-single-item__subtitle">
												<a @click.prevent="logout">Cerrar sesión</a>
											</p>
										</div>
									</div>
								</div>
								<!-- mobile menu -->
								<div
									class="mobile-navigation-icon"
									id="mobile-menu-trigger"
									@click="mobiletoggleClass('addClass', 'active')"
								>
									<i></i>
								</div>
							</div>
						</div>
						<div class="col-lg-12"></div>
					</div>
				</div>
			</div>
			<!--=======  End of header info area =======-->

			<!--=======  header navigation area  =======-->
			<fixed-header>
				<div class="header-navigation-area default-bg" style="backgroung-color: #003d74">
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<!-- header navigation -->
								<div
									class="header-navigation header-navigation--header-default position-relative"
								>
									<div class="header-navigation__nav position-static">
										<nav>
											<ul>
												<li>
													<router-link to="/">Inicio</router-link>
												</li>

												<li>
													<router-link to="/pagar">Pagar</router-link>
												</li>

												<li>
													<router-link to="/">Contáctanos</router-link>
												</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fixed-header>
			<!--=======  End of header navigation area =======-->
		</div>
	</div>
	<!--====================  End of header area  ====================-->
</template>

<script>
	import FixedHeader from 'vue-fixed-header'
	//import { isMobile } from "mobile-device-detect";
	import axios from 'axios'

	var urlLogout = process.env.VUE_APP_AUTH_API + 'api/auth/logout'

	export default {
		components: {
			FixedHeader,
		},
		data: () => ({}),
		methods: {
			checkLogin() {
				if (sessionStorage.getItem('token') != null) {
					axios
						.get(process.env.VUE_APP_AUTH_API + 'api/auth/user', {
							headers: {
								Authorization: `Bearer ` + sessionStorage.getItem('token'),
							},
						})
						.then((response) => {
							//console.log(response.data);
							if (response.status != 200) {
								this.logout()
							}
						})
						.catch((error) => {
							this.logout()
							//console.log(error);
						})
				}
			},

			// offcanvas mobilemenu
			mobiletoggleClass(addRemoveClass, className) {
				const el = document.querySelector('#offcanvas-menu')
				if (addRemoveClass === 'addClass') {
					el.classList.add(className)
				} else {
					el.classList.remove(className)
				}
			},

			logout() {
				this.$swal({
					title: `Hasta pronto ` + this.name + ' ' + this.lastname,
					html: `Regresa cuando quieras para seguir disfrutando de nuestros servicios`,
					showCancelButton: false,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
				axios
					.get(urlLogout, {
						headers: {
							Authorization: `Bearer ` + sessionStorage.getItem('token'),
						},
					})
					.then((response) => {
						console.log(response.data)
						location.href = '/'
					})
					.catch((error) => {
						console.log(error)
						this.error = true
					})
				sessionStorage.clear()
			},
		},
		computed: {
			name: function () {
				if (sessionStorage.getItem('name') !== null) {
					return sessionStorage.getItem('name')
				} else {
					return ''
				}
			},

			lastname: function () {
				if (sessionStorage.getItem('lastname') !== null) {
					return sessionStorage.getItem('lastname')
				} else {
					return ''
				}
			},
		},
	}
</script>

<style lang="scss">
	.vue-fixed-header--isFixed {
		left: 0;
		top: 0;
		width: 100vw;
		z-index: 999;
		position: fixed;
		box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
		animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
	}

	.default-bg {
		background-color: #003d74;
	}

	.header-navigation--header-default .header-navigation__nav nav > ul > li > a {
		font-size: 20px;
		padding: 10px;
	}
</style>
