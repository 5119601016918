<template>
  <!--====================  footer area ====================-->
  <div class="footer-area dark-bg">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="footer-content-wrapper section-space--inner--60">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-12">
                <FooterAbout :logo="data.footerLogo" :desc="data.footerDesc" />
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4">
                <FooterLink :title="data.footerLinkTitleOne" :nav="data.navListOne" />
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4">
                <PageLink :title="data.footerLinkTitleTwo" :nav="data.navListTwo" />
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4">
                <ContactWidget :contactWidgetData="data.footerContactWidget" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="padre">
      <div class="social-links social-links--white-topbar d-inline-block">
        <ul class="hijo">
          <li>
            <a href="https://www.facebook.com/malllikeuve" target="_blank">
              <i class="zmdi zmdi-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/MallLikeU_Ve" target="_blank">
              <i class="zmdi zmdi-twitter"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/MallLikeU_Ve/" target="_blank">
              <i class="zmdi zmdi-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>-->

    <div class="footer-copyright-wrapper">
      <div class="footer-copyright text-center">
        Copyright © 2020 LikeU Group Venezuela, C. A. RIF: J-40710655-4
        <br />Todos los derechos reservados.
      </div>
    </div>
  </div>
  <!--====================  End of footer area  ====================-->
</template>

<script>
import FooterAbout from "@/components/FooterAbout.vue";
import FooterLink from "@/components/FooterLink.vue";
import PageLink from "@/components/PageLink.vue";
import ContactWidget from "@/components/ContactWidget.vue";

import data from "../data/footer.json";
export default {
  components: {
    FooterAbout,
    FooterLink,
    ContactWidget,
    PageLink
  },
  data() {
    return {
      data
    };
  }
};
</script>

<style lang="scss" escoped>
.padre {
  margin: 1rem;
  padding: 1rem;
  /* IMPORTANTE */
  text-align: center;
}
.social-links--white-topbar {
  margin-right: 0px;
}
</style>